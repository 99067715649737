
































































































import { Component, Vue, Prop } from "vue-property-decorator";
import BTFile from "@/entity/File";
import FileManagerService from "@/services/FileManagerService";
import Loader from "@/components/common/Loader.vue";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";

@Component({
  created(this: ContactAttachment) {
    this.loadAttachments();
  },
  components: {
    Loader,
    SectionTitle,
  },
})
export default class ContactAttachment extends Vue {
  @Prop({ required: true, type: Number }) contactId!: number;
  attachments: BTFile[] = [];
  file: File | null = null;
  fileName: null | string = null;
  deletingFileId: any = {};
  isUploading = false;
  isLoading = false;
  isEditingFileName = false;
  hover = false;

  openFile = FileManagerService.openFile;

  dragover(event: DragEvent) {
    event.preventDefault();
    this.hover = true;
  }

  dragleave() {
    this.hover = false;
  }

  drop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer) {
      this.file = event.dataTransfer.files[0];
    }

    this.hover = false;
  }

  get currentInputValue(): string | File | null {
    return this.isEditingFileName ? this.fileName : this.file;
  }

  set currentInputValue(value: string | File | null) {
    if (this.isEditingFileName) {
      this.fileName = value as string | null;
    } else {
      this.file = value as File | null;
    }
  }

  get componentType(): string {
    return this.isEditingFileName ? "v-text-field" : "v-file-input";
  }

  get fileNameParts(): string[] {
    if (!this.file) {
      return [];
    }

    return this.file.name.split(".");
  }

  get fileNameWithoutExtension(): string {
    return this.fileNameParts.slice(0, -1).join(".");
  }

  get fileExtension(): string {
    if (this.fileNameParts.length === 0) {
      return "";
    }
    return `.${this.fileNameParts[this.fileNameParts.length - 1] ?? ""}`;
  }

  get newFileNameWithExtension(): null | string {
    if (!this.fileName) {
      return null;
    }

    return `${this.fileName}${this.fileExtension}`;
  }

  toggleRenameFile(): void {
    // Show rename is false to we will be displaying the section
    // Pre-set the file name for edit
    if (!this.isEditingFileName) {
      this.fileName = this.fileNameWithoutExtension;
      this.isEditingFileName = true;
    } else {
      this.isEditingFileName = false;
      this.fileName = null;
    }
  }

  reset(): void {
    this.isEditingFileName = false;
    this.file = null;
    this.fileName = null;
  }

  async uploadFile(): Promise<void> {
    try {
      this.isUploading = true;
      await FileManagerService.uploadContactAttachment(
        this.contactId,
        this.file as File,
        this.newFileNameWithExtension
      );
      this.reset();
      this.loadAttachments();
    } catch (e) {
      if (e.response.data.message === "File size should not exceed 1 MB") {
        this.$snackbarError(this.$tc("fileSizeError"));
      } else if (e.response.data.message) {
        this.$snackbarError(this.$tc("storageUnavalibleError"));
      } else {
        this.$snackbarError(this.$tc("apiErrors.unableToSave"));
      }
    } finally {
      this.isUploading = false;
    }
  }

  async deleteFile(fileId: number): Promise<void> {
    try {
      this.$set(this.deletingFileId, fileId, true);
      await FileManagerService.deleteContactAttachment(fileId);
      this.loadAttachments();
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.$delete(this.deletingFileId, fileId);
    }
  }

  async loadAttachments(): Promise<void> {
    try {
      this.isLoading = true;
      this.attachments = await FileManagerService.getContactAttachments(
        this.contactId
      );
    } catch (e) {
      this.$snackbarError(this.$tc("apiErrors.unableToLoad"));
    } finally {
      this.isLoading = false;
    }
  }
}
